// src/components/ArticlePage.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import DOMPurify from 'dompurify';
import './ArticlePage.css';

function ArticlePage() {
  const { id } = useParams(); // 获取 URL 中的文章 ID
  const [article, setArticle] = useState({ title: '', content: '' });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // 替换为您的服务器地址
    const fetchArticle = async () => {
      try {
        //const response = await axios.get(`http://localhost:8000/articles/noau/one?id=${id}`);
        const response = await axios.get(`https://www.newtritional.com/articles/noau/one?id=${id}`);
        setArticle(response.data);
      } catch (error) {
        console.error('获取文章失败：', error);
      } finally {
        setLoading(false);
      }
    };

    fetchArticle();
  }, [id]);

  if (loading) {
    return <div>加载中...</div>;
  }

  return (
    <div className="article-container">
      <h1 className="article-title">{article.title}</h1>
      <div
        className="article-content"
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(article.content) }}
      ></div>
    </div>
  );
}

export default ArticlePage;