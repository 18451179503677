// src/components/MealPlanPage/MealPlanPage.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import DOMPurify from 'dompurify';
import './MealPlanPage.css';

function MealPlanPage() {
  const { id } = useParams();
  const [mealPlan, setMealPlan] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMealPlan = async () => {
      try {
        
        //const response = await axios.get(`http://localhost:8000/mealplan/oneavailable?id=${id}`);
        const response = await axios.get(`https://www.newtritional.com/mealplan/oneavailable?id=${id}`);
        setMealPlan(response.data);
      } catch (error) {
        console.error('获取套餐信息失败：', error);
      } finally {
        setLoading(false);
      }
    };

    fetchMealPlan();
  }, [id]);

  if (loading) {
    return <div className="loading">加载中...</div>;
  }

  if (!mealPlan) {
    return <div className="error">未找到套餐信息</div>;
  }

  return (
    <div className="meal-plan-container">
      <h1 className="meal-plan-title">{mealPlan.name}</h1>
      
      {/* 套餐简介 */}
      <div className="feature-section">
        <h2>套餐特色</h2>
        <div 
          className="feature-content"
          dangerouslySetInnerHTML={{ 
            __html: DOMPurify.sanitize(mealPlan.detail) 
          }}
        ></div>
      </div>

      {/* 每日菜单 */}
      <section className="daily-menu">
        <h2>每日菜单安排</h2>
        {mealPlan.daymeals.map(day => (
          <div key={day.id} className="day-section">
            <h3 className="day-header">{day.name}</h3>
            
            {day.meals.map(meal => (
              <div key={meal.id} className="meal-section">
                <div className="meal-header">
                  <h4>{meal.name}</h4>
                  {/* 
                  <span className="meal-time">
                    {formatTime(meal.starttime)} - {formatTime(meal.endtime)}
                  </span>
                  */}
                </div>
                <ul className="dish-list">
                  {meal.dishes.map(dish => (
                    <li key={dish.id}>{dish.name}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        ))}
      </section>

      {/* 菜品详情 */}
      <section className="dishes-section">
        <h2>菜品详情</h2>
        {mealPlan.dishes.map(dish => (
          <div key={dish.id} className="dish-card">
            <h3>{dish.name}</h3>
            <div className="tags">
              {dish.tags.map((tag, index) => (
                <span key={index} className="tag">{tag}</span>
              ))}
            </div>
            
            <h4>食材</h4>
            <ul className="ingredients-list">
              {dish.ingredients.map((ingredient, index) => (
                <li key={index}>
                  {ingredient.name} {ingredient.amount}{ingredient.unit}
                </li>
              ))}
            </ul>

            <h4>制作步骤</h4>
            <ol className="steps-list">
              {dish.steps.map(step => (
                <li key={step.orderindex}>{step.detail}</li>
              ))}
            </ol>
          </div>
        ))}
      </section>
    </div>
  );
}

// 辅助函数：将秒数转换为时间格式（HH:mm）
function formatTime(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}

export default MealPlanPage;