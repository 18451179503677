import React from 'react';
import Hero from './Hero';
import '../App.css';
import { ThemeProvider } from './ThemeContext';
import FeatureList from './FeatureList';
import '../i18n'; // 确保加载i18n配置
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from 'react-i18next';
import getFeatures from '../features/entry';

const AppContent = () => {
  const { t } = useTranslation();
  const {
    features1,
    features2,
    features3,
    features4,
    features5,
    featureWidths1,
    featureWidths2,
    featureWidths3,
    featureWidths4,
    featureWidths5,
    theme
  } = getFeatures(t);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <FeatureList title={t("feature.functions")} features={features1} featureWidths={featureWidths1} customTitleClass="custom-title-class1" />
        <FeatureList title={t("feature.diary-feature")} features={features2} featureWidths={featureWidths2} customTitleClass="custom-title-class2" />
        <FeatureList title={t("feature.plan-feature")} features={features3} featureWidths={featureWidths3} customTitleClass="custom-title-class3" />
        <FeatureList title={t("feature.smart-feature")} features={features4} featureWidths={featureWidths4} customTitleClass="custom-title-class4" />
        <FeatureList title={("")} features={features5} featureWidths={featureWidths5} customTitleClass="custom-title-class4" />
      </div>
    </ThemeProvider>
  );
};

function Home() {
  return (
    <div>
      <Hero 
        buttonSize="small" 
        buttonImage="/images/download.svg" 
        buttonLink="https://apps.apple.com/us/app/新煮意/id6463604621"
        imageWidth="45%"
      />
      <div className="container">
        {<AppContent />}
      </div>
    </div>
  );
}

export default Home;
